@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@400;700&display=swap');

body {
  overflow-x: hidden;
}

.fontMohave {
  font-family: 'Mohave', serif;
}

.fontMerriweather {
  font-family: 'Merriweather', serif;
}

.fontLato {
  font-family: 'Lato', serif;
}

.fontLatoBlack h1 {
  font-family: 'Lato', serif;
  font-weight: 900 !important;
}

.fontLatoBold h2 {
  font-family: 'Lato', serif;
  font-weight: 700 !important;
}

@media (max-width: 430px) {
  .fontLatoBold h2 {
  font-family: 'Lato', serif;
  font-weight: 700 !important;
  font-size: 20px;
  }
}

.fontInter {
  font-family: 'Inter', serif;
}

.fontRoboto {
  font-family: 'Roboto', serif;
}

.botonRegresarflotante {

    position: fixed;
    bottom: 22px;
    right: 30%;
    z-index: 9999;
    background: #829fd7;
    border: none;
    outline: none;
    width: 112px;
    height: 68px;
    border-radius: 30px 30px 30px 30px;
    display: none; /* Oculto por defecto */
}

.botonRegresarflotante.show {
    display: block;
}

.botonRegresar {
  position: fixed;
  bottom: 22px;
  right: 4%;
  z-index: 9999;
  background: #829FD9;
  border: none;
  outline: none;
  width: 67px;
  height: 68px;
  border-radius: 30px 30px 30px 30px;
}

.botonVolver {
  border: none;
  outline: none;
  border-radius: 30px 30px 30px 30px;
  color: #929292;
  padding-left: 1%;
  padding-right: 1%;
}

.divBarraNavegacion img {
  width: 410px;
  height: 99px;
}

.maxHeight {
  height: 100%;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1760px !important;
  }

  .navbar .container {
    white-space: nowrap;
  }
}

@media (max-width: 430px) {
  .divBarraNavegacion img {
    width: 253px !important;
    height: auto !important;
  }

  .contenedorNavBar {
    width: auto !important;
    padding: 50px 0px 50px 0px;
    justify-content: start !important;
    display: inline !important;
  }

  /* .contenedorNavBarLogo {
    width: auto !important;
    padding: 50px 0px 50px 0px;
    justify-content: start !important;
    display: inline !important;
    margin-left: 50px;
    margin-right: auto;
  } */

  .navbar-brand {
    margin: auto !important;
    justify-content: start !important;
  }

  .navbar-collapse {
    margin-top: 25px !important;
  }
}

.divBarraNavegacion .dropdown-menu {
  --bs-dropdown-bg: #262626 !important;
  color: white !important;
}

.divBarraNavegacion .dropdown-item {
  color: white !important;
  padding: 25px 30px 25px 30px;
  text-align: center;
}

.divBarraNavegacion .dropdown-item:hover {
  background-color: #54678C !important;
}

.myNavBar {
  background-color: #262626 !important;
  color: rgb(255, 255, 255);
}

.contenedorNavBar {
  width: auto !important;
}

.nav-link {
  color: white !important;
  padding: 29px 43px 29px 43px !important;
}

.nav-link:hover {
  background-color: #54678C;
  ;
}

.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar-toggler {
  color: #00000000 !important;
  background-color: #00000000 !important;
  border: none !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba(255, 255, 255, 0.75)%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

/* CHATBOT */



/* BANNER */

@media (max-width: 430px) {
  .cuadro-contenedor {
    display: none !important;
  }

  .filtro {
    display: block !important;
  }
}

.cuadro-contenedor {
  position: absolute;
  overflow: hidden;
  /* Asegura que el contenido que se salga del contenedor no sea visible */
}

.cuadro1-contenedor {
  top: 0%;
  left: 0%;
  width: 19%;
  height: 25%;
}

.cuadro2-contenedor {
  top: 0%;
  left: 20%;
  width: 60%;
  height: 25%;
}

.cuadro3-contenedor {
  top: 0%;
  left: 81%;
  width: 19%;
  height: 25%;
}

.cuadro4-contenedor {
  top: 29%;
  left: 0%;
  width: 19%;
  height: 71%;
}

.cuadro5-contenedor {
  top: 29%;
  left: 20%;
  width: 60%;
  height: 43%;
}

.cuadro6-contenedor {
  top: 29%;
  left: 81%;
  width: 19%;
  height: 43%;
}

.cuadro7-contenedor {
  top: 76%;
  left: 20%;
  width: 35%;
  height: 24%;
}

.cuadro8-contenedor {
  top: 76%;
  left: 56%;
  width: 44%;
  height: 24%;
}

.cuadro {
  position: absolute;
  background-color: #00000040;
  transition: height 0.3s ease;
  width: 100%;
  height: 100%;
}

.cuadro1-contenedor:hover .cuadro {
  height: 50%;
}

.cuadro2-contenedor:hover .cuadro {
  height: 50%;
}

.cuadro3-contenedor:hover .cuadro {
  height: 50%;
}

.cuadro4-contenedor:hover .cuadro {
  height: 50%;
}

.cuadro6-contenedor:hover .cuadro {
  height: 50%;
}

.cuadro7-contenedor:hover .cuadro {
  height: 50%;
}

.cuadro8-contenedor:hover .cuadro {
  height: 50%;
}



.contenedor-imagen {
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-bottom: 6%
}

.contenedor-imagen img {
  display: block;
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.filtro {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: #26262680;
  display: none;
}

.segunda-imagen {
  object-fit: auto;
  position: absolute;
  width: 25% !important;
  height: auto !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.contenedor-introduccion {
  width: 49%;
  margin: auto;
  @media (max-width: 768px) {
    width: 80%;
  }
}

.contenedor-introduccion p {
  text-align: justify !important;
  line-height: 1;
  font-size: 20px;
}

.contenedor-introduccion ul {
  font-size: 20px;
}

@media (max-width: 430px) {

  .contenedor-imagen {
    margin-bottom: 12% !important
  }

  .contenedor-imagen img {
    display: block;
    width: 100%;
    height: 264px;
    object-fit: cover;
  }

  .segunda-imagen {
    object-fit: auto;
    position: absolute;
    width: 50% !important;
    height: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


/* Introduccion */

@media (max-width: 430px) {
  .cardProfesionales img {
    width: 94px !important;
    height: 94px !important;
  }
}


.barra {
  color: rgba(255, 184, 0, 1);
  border-left-width: 20px;
  border-left-style: solid;
  min-height: 100px;
  position: absolute;
  margin-left: -50px;
  margin-top: 6px;
}

.barra_sec {
  color: #c1c9d9;
  border-left-width: 20px;
  border-left-style: solid;
  min-height: 100px;
  position: absolute;
  margin-left: -50px;
  margin-top: 6px;
}

.barraColores {
  width: 177px;
  height: 9px;
  background: linear-gradient(to right, 
    #E40232 11.11%, 
    #944E99 11.11%, #944E99 22.22%, 
    #00A39C 22.22%, #00A39C 33.33%, 
    #2871B8 33.33%, #2871B8 44.44%, 
    #00A439 44.44%, #00A439 55.55%, 
    #E40232 55.55%, #E40232 66.66%, 
    #944E99 66.66%, #944E99 77.77%, 
    #00A39C 77.77%, #00A39C 88.88%, 
    #2871B8 88.88%);
  margin: 10px auto 0;
}

.divProfesionales .nombreProfesional {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  margin-bottom: 20px;
}

.divProfesionales .informacionProfesional {
  margin-bottom: 20px;
}

.cardProfesionales {
  background-color: #c1c9d9 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 4% 3% 4% 3%;
}


/* Barra de busqueda */

.inputBusqueda {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.inputBusqueda button {
  border-radius: 50px;
  border: 1px;
  border-color: #9A9A9A;
  width: 20%;
  color: white;
  background: #262626;
}

@media (max-width: 430px) {
  .inputBusqueda button {
    width: 30%;
  }
}
.inputBusqueda h2 {
  margin-top: 4% !important;
  margin-bottom: 4% !important;
}

@media (max-width: 430px) {
  .inputBusqueda .input-container {
    width: 178px !important;
  }

  .inputBusqueda .input-container input {
    border: none;
    margin-top: 4% !important;
    margin-bottom: 4% !important;
    margin-left: 10% !important;
    width: 70% !important;
  }
}

.inputBusqueda .input-container {
  border-radius: 50px;
  border: 1px solid #9A9A9A;
  width: 40%;
}

.inputBusqueda .input-container svg {
  margin-left: 6%;
}

.inputBusqueda .input-container input {
  border: none;
  margin-top: 4% !important;
  margin-bottom: 4% !important;
  margin-left: 4% !important;
  width: 80%;
}

.inputBusqueda .input-container input:focus {
  outline: none;
}


/* Acordeon Software */

.divSoftware {
  margin: 0 14% 0;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.divVideotutoriales {
  margin: 0 14% 0;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.divPreguntasFrecuentes {
  margin: 0 14% 0;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.divProfesionales {
  margin: 0 14% 0;
  margin-bottom: 30px;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    margin: 0;
  }

}



@media (max-width: 430px) {
  .divSoftware .tituloSoftware {
    font-size: 16px !important;
  }

  .botonDescargarSoftware {
    width: 82px !important;
    height: 36px !important;
    font-size: 10px !important;
  }

  .divSoftware .colBody {
    padding-left: 5px !important;
  }

  .divSoftware .imgCaratula {
    width: 56.73px !important;
    height: 150px !important;
  }

  .divSoftware .colInformacion {
    margin-left: 0px !important
  }

  .softwareCaratula {
    width: 45px !important;
  }
}

@media (min-width: 1300px) {

  .botonDescargarSoftware {
    width: 208.54px !important;
    height: 50px !important;
  }
}

.descripcionSoftware {
  height: 39%;
  max-height: 39%;
  color: #f2f2f2d3;
}

.softwareCaratula {
  width: 66.73px;
  height: 169px;
  background: #C1C9D9;
}

.divSoftware .imgCaratula {
  width: 66.73px;
  height: 169px;
}

.divSoftware .accordion {
  --bs-accordion-bg: #54678c !important;
  --bs-accordion-active-bg: #54678c !important;
}

.divSoftware .accordion-item {
  min-height: 59px;
  margin-bottom: 30px;
}

.divSoftware .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.divSoftware .accordion-button {
  color: white !important;
  font-size: 32px;
}

.divSoftware .accordion-button::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>') !important;
}

.divSoftware .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: none !important;
}

.divSoftware .accordion-body {
  background-color: #364159;
  color: white;
}

.divSoftware .colBody {
  padding-left: 100px;
}

.divSoftware .colInformacion {
  margin-left: 10px;
  max-width: 900px;
}

.divSoftware .tituloSoftware {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #F2F2F2;
  margin-bottom: 10px;
  margin-top: 10px;
}

.botonDescargarSoftware {
  background: #364159;
  border: 3px solid #FFFFFF;
  border-radius: 50px;
  color: white;
  transition: background-color 0.3s ease;
}

.botonDescargarSoftware:hover {
  animation-timing-function: ease-out;
  animation-duration: 250ms;
  background: #646464;
}

/* Videotutoriales */

.divVideotutoriales .accordion {
  --bs-accordion-bg: #54678c !important;
  --bs-accordion-active-bg: #54678c !important;
  border-radius: 0% !important;
}

.divVideotutoriales .accordion-item {
  min-height: 59px;
  margin-bottom: 30px;
  border-radius: 0% !important;
}

.divVideotutoriales .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.divVideotutoriales .accordion-button {
  color: white !important;
  font-size: 32px;
}

.divVideotutoriales .accordion-button::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>') !important;
}

.divVideotutoriales .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: none !important;
}
.divVideotutoriales .accordion-button::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>') !important;
}

.divVideotutoriales .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: none !important;
}

.divVideotutoriales .accordion-body {
  background-color: #364159;
  color: white;
}

.divVideotutoriales {
  background: #ffffff !important;
}
.divVideotutoriales .video-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.divVideotutoriales .video-item {
  width: 45%;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 768px) {
  .divVideotutoriales .video-item {
    width: 100%;
  }
}

.divVideotutoriales .contenedorReproductor {
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  height: auto; 
  aspect-ratio: 16 / 9;
  overflow: hidden;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

.divVideotutoriales .contenedorReproductor .tituloVideo {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
}


.divVideotutoriales .contenedorReproductor iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #9A9A9A;
}

/* Preguntas Frecuentes Accordion */

/* Slide-in Animation for the FAQ accordion items */

.divPreguntasFrecuentes .nombreCategoria {
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  color: #F2F2F2;
  margin-left: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.divPreguntasFrecuentes .iconoCategoria {
  text-align: right;
  margin-right: 30px;
  margin-top: 6px;
}

.divPreguntasFrecuentes .cuadroPreguntasFrecuentes {
  background-color: #364159;
  margin-bottom: 25px;
  margin-top: 0;
}

.divPreguntasFrecuentes button {
  background-color: #54678c;
  width: 100%;
  color: white;
  min-height: 70px;
  margin-top: 30px;
  border: none;
  font-size: 32px;
  line-height: 38.4px;
}

.divPreguntasFrecuentes .cuadroPreguntasFrecuentes .pregunta {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #F2F2F2;
  margin-left: 60px !important;
}

.divPreguntasFrecuentes .cuadroPreguntasFrecuentes .respuesta {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #F2F2F2;
  margin-left: 60px !important;
  margin-right: 0 !important;
  padding-bottom: 25px;
}

.divPreguntasFrecuentes .accordion-button {
  color: white !important;
  background-color: #54678c !important;
  font-size: 32px;
  border: none;
}

.divPreguntasFrecuentes .accordion-button::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>') !important;
}

.divPreguntasFrecuentes .accordion-body {
  background-color: #364159;
  color: white;
}

@media (max-width: 430px) {
  .divPreguntasFrecuentes .nombreCategoria {
    font-size: 32px !important;
  }

  .divPreguntasFrecuentes button {
    font-size: 32px !important;
    height: 36px !important;
  }
}

@media (min-width: 1300px) {
  .divPreguntasFrecuentes button {
    font-size: 32px !important;
    height: 50px !important;
  }
}

/* Contacto */

.divContacto {
  width: 540px;
  margin: 0 auto;
  margin-bottom: 100px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

@media (max-width: 430px) {
  .divContacto {
    width: 100% !important;
  }

  .divContacto .encabezadoInput svg {
    margin-left: 0px !important;
  }

  .divContacto .btnEnviar {
    margin-right: 45px !important
  }

  .divContacto .btnEnviarDeshabilitado {
    margin-right: 45px !important
  }
}

.divContacto h1 {
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
  text-align: left;
}

.divContacto .accordion {
  --bs-accordion-bg: #f2cc72 !important;
  --bs-accordion-active-bg: #f2cc72 !important;
}

.divContacto .accordion-button:focus {
  box-shadow: none !important;
}

.divContacto .accordion-body {
  background-color: #F2F2F2 !important;
  min-height: 626px !important;
  color: black;
}

.divContacto .accordion-button {
  padding: 0px 45px 0px 45px;
}

.divContacto .accordion-header button {
  padding-top: 53px;
  padding-bottom: 30px;
  color: black;
}

.divContacto .input-container {
  position: relative;
  width: 429px;
  margin: auto;
  margin-bottom: 20px;
}

.divContacto .input-container input[type="text"] {
  padding-right: 30px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  background-color: #00000000;
  margin-top: 10px;
}

.divContacto .input-container .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: default;
}

.divContacto .input-container svg {
  vertical-align: top;
}

.divContacto .encabezadoInput {
  width: 514px;
  margin: auto;
  color: #847D7D;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
}

.divContacto .mensaje {
  resize: none;
  padding-right: 30px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  background-color: #00000000;
  margin-top: 10px;
}

.divContacto .encabezadoInput svg {
  vertical-align: top;
  margin-left: 0 px;
  margin-right: 10px;
}

.divContacto .avisoCorreo {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: #030BA6;
  text-align: center;
}

.divContacto .btnEnviar {
  text-align: right;
  margin-right: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.divContacto .btnEnviar button {
  border-radius: 100%;
  height: 65px;
  width: 65px;
  background-color: #7272df;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
}

.divContacto .btnEnviar button:hover {
  background-color: #6060f7;
}

.divContacto .btnEnviarDeshabilitado {
  text-align: right;
  margin-right: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.divContacto .btnEnviarDeshabilitado button {
  border-radius: 100%;
  height: 65px;
  width: 65px;
  background-color: #B1B1B5;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
}

.myCaptcha {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

#ButtonDiv {
  position: fixed;
  bottom: 22px;
  right: 10%;
  z-index: 9998;
}
#volverButton {
  position: fixed;
  bottom: 22px;
  right: 150px;
  z-index: 9998;
  background: #85a0db;
  border: none;
  outline: none;
  width: 68px;
  height: 68px;
  border-radius: 30px 30px 30px 30px;
  @media (max-width: 768px) {
    display: none;
  }
}

.responsive-circle-image {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.modalContactanos .modal-content {
  background-color: #F2F2F2;
  border-radius: 63px 63px 63px 63px;
  border: 1px 0px 0px 0px;
}

.modalContactanos .modal-header {
  background-color: #F3CC73;
  border-radius: 63px 63px 0px 0px;
  padding-bottom: 7% !important;
  margin-bottom: 2% !important;
}

.modalContactanos .modal-body {
  text-align: center !important;
}

.modalContactanos .modal-footer {
  text-align: center !important;
  justify-content: center !important;
  border-top: none !important;
}

.modalContactanos .btn {
  background-color: #54678C !important;
  color: #F2F2F2;
}

.modalContactanos .msg{
  font-size: 30px;
}




.myFooter {
  background-color: #262626;
  color: #ffffff;
  padding: 80px 0;
}
.barrafooter {
  color: #F3CC73;
  border-left-width: 20px;
  border-left-style: solid;
  min-height: 42px;
  position: absolute;

  
}
.contenedorFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

}

.footer-logo {
  width: 386px;
  height: auto;
}



.footer-nav {
  list-style: none;

  padding-left: 0;
  margin-bottom: 0;
}

.footer-link {
  color: white;
  padding: 5px 0px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}



@media (min-width: 1200px) {
  .container {
    max-width: 1760px !important;
  }
}

@media (max-width: 430px) {
  .footer-logo {
    width: 253px !important;
    height: auto !important;
  }

  .footer-nav {
    flex-direction: column;
    align-items: center;
  }

  .footer-link {
    padding: 10px 0;
  }
}
@media (min-width: 768px) {
  .web-margin-footer {
    margin-left: 30px;
  }
}

