@media (max-width: 1366px) {
  #chatWindow{
    height: 92% !important
  }
}

#chatButton {
    position: fixed;
    z-index: 9999;
    background: #FFB800;
    border: none;
    outline: none;
    width: 68px;
    height: 68px;
    border-radius: 30px 30px 30px 30px;
    bottom: 22px;
    right: 250px;
  }
  
  #chatWindow {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 120px;
    width: 492px;
    max-width: 492px;
    height: 81%;
    max-height: 692px;
    z-index: 9999;
    background: #FFB800 !important;
    border-radius: 30px 30px 30px 30px;
  }

  @media (max-width: 430px) {
    #chatWindow {
      display: block;
      position: fixed;
      bottom: 20px;
      right: 40px;
      width: 492px;
      max-width: 492px;
      height: 81%;
      max-height: 692px;
      z-index: 9999;
      background: #FFB800 !important;
      border-radius: 30px 30px 30px 30px;
    }
  }
  
  #chatbotHeader {
    background: #1A1A1A !important;
    border-radius: 30px 30px 0px 0px;
    height: 16%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #chatbotHeader h1 {
    text-align: center;
    color: white;
    font-size: 25px;
    margin: 0;
  }
  
  @media (max-width: 430px) {
  
    #chatButton {
      right: 10px !important;
    }
  
    #chatWindow {
      right: 4px;
      width: 348px;
      max-width: 348px;
      z-index: 9999;
      background: #FFB800 !important;
      border-radius: 30px 30px 30px 30px;
    }
  
    #chatWindow .btnOpcionesChatbot {
      margin-left: 20px !important;
    }
  
    #chatWindow #btnCerrar {
      width: 49px !important;
      height: 55px !important;
    }
  
    .contentChatbot {
      width: 314px !important;
    }
  }
  
  #chatWindow #btnCerrar {
    border: none;
    outline: none;
    background: #FFB800;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 64px;
    height: 54px;
    max-width: 64px;
    max-height: 54px;
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    right: 20px
  }
  
  #chatWindow .dialogo {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  #chatWindow .cuadroPreguntaRespuesta {
    margin-right: 20px;
  }
  
  #chatWindow .btnOpcionesChatbot {
    text-align: center;
    border: none;
    outline: none;
    width: 150px;
    height: 57px;
    background-color: #F2F2F2;
    border-radius: 21px 21px 21px 21px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-size: 14px;
  }
  
  #chatWindow .btnOpcionesChatbot:hover {
    background-color: #dadada;
  }
  
  .contentChatbot {
    width: 454px;
    height: 77%;
    margin: auto;
    background-color: white;
    border: 3px solid #000000;
    border-radius: 35px 35px 35px 35px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .contentChatbot .imagen {
    margin-left: 15px;
  }
  
  .contentChatBot .myRow {
    padding-left: -15px !important;
    --bs-gutter-x: 0.5rem !important;
  }
  
  .cuadro-dialogo {
    position: relative;
   
    border-radius: 21px;
    padding: 5px 15px 5px 15px;
    margin-right: 15px;
    text-align: center;
    
  }

  .cuadro-dialogo.bot {
    max-width: 80%;
    background-color: #FFB800;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  .cuadro-dialogo.user {
    background-color: #F2F2F2;
    max-width: 60%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
 

   
  .triangulo {
    position: absolute;
    top: 10%;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  
  .triangulo.bot {
    left: -12px;
    border-right: 20px solid #FFB600;
  }
  
  .triangulo.user {
    right: -12px;
    border-left: 20px solid #F2F2F2;
  }
  
  .contenedorChatbotSuperior {
    height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .chatBotInputContainer {
    width: 80%;
    margin: auto;
    padding-top: 2px;
  }
  
  .chatBotInputContainer input {
    width: 100%;
    background-color: rgb(238, 238, 238);
    border-radius: 20px 20px 20px 20px;
    padding: 10px 10px 10px 10px;
    outline: none;
    border: none;
  }

  .typingIndicator {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
  }
  
  .typingIndicator .ellipsis {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #333;
    margin: 0 2px;
    animation: ellipsis 1.2s infinite;
  }
  
  .typingIndicator .ellipsis:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typingIndicator .ellipsis:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typingIndicator .ellipsis:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes ellipsis {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }



  /* Para WebKit browsers */
.contenedorChatbotSuperior::-webkit-scrollbar {
  width: 8px;
}

.contenedorChatbotSuperior::-webkit-scrollbar-thumb {
  background-color: #FFB800;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.contenedorChatbotSuperior::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}



#MiniBannerChat {
  background-color: #F2F2F2; /* Color de fondo */
  color: rgb(0, 0, 0); /* Color del texto */
  padding: 10px;
  text-align: center;
  position: sticky; 
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.288);

  z-index: 1000; 
  height:  30px;
  font-size:  11px;

  top: 0; 
}

.chat-avatar {
  width: 75px;
  height: 75px;
}

@media (max-width: 767px) {
  .chat-avatar {
    width: 55px;
    height: 55px;
  }
}

.thumbs {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.thumbs p {
  font-size: small;
  margin-bottom: 0 !important
}

.circulo {
  width: 57px;
  height: 55.5px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chatBotEmail {
  position: relative;
  padding: 5%;
}

.chatBotEmail input {
  width: 70%;
  background-color: rgb(238, 238, 238);
  border-radius: 20px 20px 20px 20px;
  padding: 10px 10px 10px 10px;
  margin-right: 5%;
  outline: none;
  border: none;
}

@media (max-width: 430px) {
  .chatBotEmail input {
    width: 60%;
    background-color: rgb(238, 238, 238);
    border-radius: 20px 20px 20px 20px;
    padding: 10px 10px 10px 10px;
    margin-right: 5%;
    outline: none;
    border: none;
  }
}

.chatBotEmail button {
  border-radius: 50px;
  border: 1px;
  border-color: #9A9A9A;
  width: 25%;
  color: white;
  background: #262626;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 430px) {
  .chatBotEmail button {
    border-radius: 50px;
    border: 1px;
    border-color: #9A9A9A;
    width: 35%;
    color: white;
    background: #262626;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
